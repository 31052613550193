/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
// import BackgroundImage from 'gatsby-background-image';

import './ImageZoom.styl';
import Image from '../Image';

const ImageZoom = ({ image, imageTitle }) => {
    const imageRef = useRef(null);

    const handleMouseLeave = () => {
        imageRef.current.lastChild.getElementsByTagName(
            'img',
        )[1].style.transform = 'scale(1)';
    };

    const handleMouseEnter = () => {
        imageRef.current.lastChild.getElementsByTagName(
            'img',
        )[1].style.transform = 'scale(1.5)';
    };

    const handleMouseMove = (e) => {
        imageRef.current.lastChild.getElementsByTagName(
            'img',
        )[1].style.transformOrigin = `${
            ((e.offsetX - e.target.offsetLeft) / e.target.offsetWidth) * 100
        }% ${
            ((e.offsetY - e.target.offsetTop) / e.target.offsetHeight) * 100
        }%`;
    };

    useEffect(() => {
        if (
            imageRef &&
            imageRef.current &&
            imageRef.current.lastChild &&
            imageRef.current.lastChild.getElementsByTagName('img')[1]
        ) {
            imageRef.current.lastChild
                .getElementsByTagName('img')[1]
                .addEventListener('mouseenter', handleMouseEnter);
            imageRef.current.lastChild
                .getElementsByTagName('img')[1]
                .addEventListener('mousemove', handleMouseMove);
            imageRef.current.lastChild
                .getElementsByTagName('img')[1]
                .addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (
                imageRef &&
                imageRef.current &&
                imageRef.current.lastChild &&
                imageRef.current.lastChild.getElementsByTagName('img')[1]
            ) {
                imageRef.current.lastChild
                    .getElementsByTagName('img')[1]
                    .removeEventListener('mouseenter', handleMouseEnter);
                imageRef.current.lastChild
                    .getElementsByTagName('img')[1]
                    .removeEventListener('mousemove', handleMouseMove);
                imageRef.current.lastChild
                    .getElementsByTagName('img')[1]
                    .removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    });

    // console.log(imageRef.current.lastChild);

    return (
        <div ref={imageRef} className='img-wrapper'>
            <Image
                image={image.gatsbyImageData}
                alt={imageTitle}
                objectFit='contain'
                objectPosition='50% 50%'
                style={{
                    maxHeight: '100%',
                }}
                imgStyle={{
                    border: '1px solid rgba(142,141,142,0.15)',

                    margin: '0 auto',
                    right: 0,
                    width: 'auto',
                }}
            />
        </div>
    );
};

ImageZoom.propTypes = {
    image: PropTypes.object.isRequired,
    imageTitle: PropTypes.string.isRequired,
};

export default ImageZoom;
