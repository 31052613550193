/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Breadcrumb from '../Breadcrumb';
import Image from '../Image';
import ShareButtons from '../ShareButtons';
import Slider from '../Slider';

import './Small.styl';

const Small = ({ breadcrumb, list, location, products, seoImage }) => (
    <>
        <Image
            fluid={products.fields.imageSrc.childImageSharp.gatsbyImageData}
            alt={products.titre.join(' - ')}
        />
        <div className='product-info-small'>
            <h1>{products.titre[1]}</h1>
            <h2>{products.titre[0]}</h2>
            <Breadcrumb
                crumbs={breadcrumb.crumbs}
                title={products.titre.join(' - ')}
            />
            <div className='product-description'>
                {products.descriptif_collection.map((item, i) => (
                    <div key={i}>{item}</div>
                ))}
            </div>

            <ul className='product-attr'>
                {products.descriptif_produit.map((item, i) => (
                    <li key={i}>{item}</li>
                ))}
            </ul>
            <div className='copyright'>
                {products.copyright === null || products.copyright === '' ? (
                    'D.R'
                ) : products.site_artiste === null ||
                  products.site_artiste === '' ? (
                    products.copyright
                ) : (
                    <a
                        href={products.site_artiste}
                        target='_blank'
                        rel='noreferrer'
                    >
                        {products.copyright}
                    </a>
                )}
            </div>
            <div className='product-share'>
                <FormattedMessage tagName='p' id='product.share' />
                <ShareButtons
                    url={location.pathname}
                    description={products.titre.join(' - ')}
                    media={seoImage.fields.imageSrc.childImageSharp.resize.src}
                />
            </div>
            <div className='same-collection'>
                <FormattedMessage tagName='h3' id='product.related' />
                <Slider data={list} slidesToShow={3} />
            </div>
        </div>
    </>
);

Small.propTypes = {
    breadcrumb: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    products: PropTypes.object.isRequired,
    seoImage: PropTypes.object.isRequired,
};

export default Small;
