import PropTypes from 'prop-types';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { injectIntl } from 'react-intl';
import {
    FacebookShareButton,
    TwitterShareButton,
    PinterestShareButton,
    LinkedinShareButton,
    FacebookIcon,
    TwitterIcon,
    PinterestIcon,
    LinkedinIcon,
} from 'react-share';

import './Share.styl';

const ShareButtons = ({ description, media, url }) => {
    // const formattedDescription = formatMessage({ id: description });
    const data = useStaticQuery(graphql`
        query siteUrl {
            site {
                siteMetadata {
                    siteUrl
                }
            }
        }
    `);
    const path = data.site.siteMetadata.siteUrl + url;
    const pathMedia = data.site.siteMetadata.siteUrl + media;

    return (
        <div className='post-social'>
            <FacebookShareButton
                url={path}
                className='button is-outlined is-rounded facebook'
            >
                <FacebookIcon
                    size={44}
                    round
                    bgStyle={{ fill: '#f3f4fa' }}
                    iconFillColor='#9b9b9b'
                />
            </FacebookShareButton>

            <TwitterShareButton
                url={path}
                className='button is-outlined is-rounded twitter'
            >
                <TwitterIcon
                    size={44}
                    round
                    bgStyle={{ fill: '#f3f4fa' }}
                    iconFillColor='#9b9b9b'
                />
            </TwitterShareButton>

            <PinterestShareButton
                url={path}
                media={pathMedia}
                description={description}
            >
                <PinterestIcon
                    size={44}
                    round
                    bgStyle={{ fill: '#f3f4fa' }}
                    iconFillColor='#9b9b9b'
                />
            </PinterestShareButton>

            <LinkedinShareButton url={path}>
                <LinkedinIcon
                    size={44}
                    round
                    bgStyle={{ fill: '#f3f4fa' }}
                    iconFillColor='#9b9b9b'
                />
            </LinkedinShareButton>
        </div>
    );
};

ShareButtons.propTypes = {
    description: PropTypes.string.isRequired,
    media: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default injectIntl(ShareButtons);
