import clsx from 'clsx';
import { ResponsiveContext } from 'grommet';
import React, { useContext } from 'react';

import Container from '../Container';
import Large from './Large';
import Small from './Small';

import './index.styl';

const ProductItemContainer = (props) => {
    const size = useContext(ResponsiveContext);
    const isSmall = size === 'small';
    return (
        <Container
            product={isSmall}
            className={clsx('product-item-container', { isSmall })}
        >
            {isSmall ? <Small {...props} /> : <Large {...props} />}
        </Container>
    );
};

ProductItemContainer.propTypes = {};

export default ProductItemContainer;
