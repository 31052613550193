import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import './Breadcrumb.styl';

const Breadcrumb = ({ crumbs, title }) => (
    <ul className='breadcrumb-component'>
        {crumbs
            .map((crumb, i) => (
                <li key={crumb.crumbLabel}>
                    <Link
                        to={crumb.pathname}
                        disabled={!crumbs[i + 1]}
                        className={crumbs[i + 1] ? '' : 'disabled-link'}
                    >
                        {title !== null && !crumbs[i + 1]
                            ? title
                            : crumb.crumbLabel}
                    </Link>
                </li>
            ))
            .reduce((prev, curr) => [
                prev,
                <li
                    className='breadcrumb-separator'
                    key={`breadcrumb-separator-${prev.crumbLabel}`}
                >
                    /
                </li>,
                curr,
            ])}
    </ul>
);

Breadcrumb.defaultProps = {
    title: null,
};

Breadcrumb.propTypes = {
    crumbs: PropTypes.array.isRequired,
    title: PropTypes.string,
};

export default Breadcrumb;
