/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DEFAULT_IMAGE_NAME } from '../../../constants/global';
import Breadcrumb from '../Breadcrumb';
import ImageZoom from '../ImageZoom';
import ShareButtons from '../ShareButtons';
import Slider from '../Slider';

import './Large.styl';

const Large = ({ breadcrumb, list, location, products, seoImage }) => (
    <>
        <div className='product-item-bloc'>
            <div className='left-content'>
                {products.fields.imageSrc !== null && (
                    <ImageZoom
                        image={products.fields.imageSrc.childImageSharp}
                        imageTitle={products.titre.join(' - ')}
                    />
                )}
                {products.fields.imageBisSrc !== null &&
                    products.fields.imageBisSrc.name !== DEFAULT_IMAGE_NAME && (
                        <ImageZoom
                            image={products.fields.imageBisSrc.childImageSharp}
                            imageTitle={products.titre.join(' - ')}
                        />
                    )}
                {/* <div className='same-collection'>
                    <FormattedMessage tagName='h3' id='product.related' />
                    <Slider data={list} slidesToShow={3} />
                </div> */}
            </div>
            <div className='right-content'>
                <div className='fixed'>
                    <div className='align-left'>
                        <Breadcrumb
                            crumbs={breadcrumb.crumbs}
                            title={products.titre.join(' - ')}
                        />
                    </div>
                    <div className='product-info'>
                        <h1>{products.titre[1]}</h1>
                        <h2>{products.titre[0]}</h2>
                        <div className='product-description'>
                            {products.descriptif_collection.map((item, i) => (
                                <div key={i}>{item}</div>
                            ))}
                        </div>
                        <ul className='product-attr'>
                            {products.descriptif_produit.map((item, i) => (
                                <li key={i}>{item}</li>
                            ))}
                        </ul>
                        <div className='copyright'>
                            {products.copyright === null ||
                            products.copyright === '' ? (
                                'D.R'
                            ) : products.site_artiste === null ||
                              products.site_artiste === '' ? (
                                products.copyright
                            ) : (
                                <a
                                    href={products.site_artiste}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    {products.copyright}
                                </a>
                            )}
                        </div>
                    </div>
                    <div className='product-share'>
                        <ShareButtons
                            url={location.pathname}
                            description={products.titre.join(' - ')}
                            media={
                                seoImage.fields.imageSrc.childImageSharp.resize
                                    .src
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='same-collection'>
            <FormattedMessage tagName='h3' id='product.related' />
            <Slider data={list} slidesToShow={5} />
        </div>
    </>
);

Large.propTypes = {
    breadcrumb: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    products: PropTypes.object.isRequired,
    seoImage: PropTypes.object.isRequired,
};

export default Large;
