import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import ProductItemContainer from '../components/ProductItemContainer/index';
import SEO from '../components/SEO';
import MainLayout from '../layouts/MainLayout';

const ProductItem = ({
    data,
    location,
    pageContext: { breadcrumb, locale, translations },
}) => {
    const { products, seoImage } = data;
    return (
        <MainLayout
            locale={locale}
            translationsPaths={translations}
            neverTransparent
        >
            <SEO
                title={products.titre.join(' - ')}
                location={location}
                translationsPaths={translations}
                description={products.descriptif_collection.join(' ')}
                pageType='product'
                product={products}
            />
            <div className='header-spacer' />
            <main id='content' className='product'>
                <ProductItemContainer
                    list={products.relatedProducts}
                    products={products}
                    breadcrumb={breadcrumb}
                    location={location}
                    seoImage={seoImage}
                />
            </main>
        </MainLayout>
    );
};

ProductItem.propTypes = {
    data: PropTypes.shape({
        products: PropTypes.object.isRequired,
        seoImage: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        breadcrumb: PropTypes.object.isRequired,
        locale: PropTypes.string.isRequired,
        productPath: PropTypes.string.isRequired,
        translations: PropTypes.array.isRequired,
    }).isRequired,
};

export default ProductItem;

export const query = graphql`
    query ($productPath: String!) {
        products: products(fields: { path: { eq: $productPath } }) {
            ...ProductDetail
        }
        seoImage: products(fields: { path: { eq: $productPath } }) {
            ...ProductSeoImage
        }
    }
`;
